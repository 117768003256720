import { render, staticRenderFns } from "./ContactForm.vue?vue&type=template&id=5599a1b1&"
import script from "./ContactForm.vue?vue&type=script&lang=ts&"
export * from "./ContactForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ContactForm.vue?vue&type=custom&index=0&blockType=i18n&locale=es&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./ContactForm.vue?vue&type=custom&index=1&blockType=i18n&locale=en&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MaterialIcon: require('/tmp/build_6958f57a/components/MaterialIcon.vue').default,Button: require('/tmp/build_6958f57a/components/Button.vue').default,TextInput: require('/tmp/build_6958f57a/components/TextInput.vue').default,TextArea: require('/tmp/build_6958f57a/components/TextArea.vue').default,Button: require('/tmp/build_6958f57a/components/Button.vue').default})

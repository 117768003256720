import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1b29b4b6 = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _057c1dc8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _11ceb88a = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _6a07f134 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _6400fefa = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _25569585 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _f14ca90a = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _04dec614 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _b5fb079a = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _becdf8b4 = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _931fe1ec = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _eafad8d8 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _6a63159d = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _baf82d82 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _11689c7e = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _19233ba3 = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _ef17bbc8 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _993593ea = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _54a27e96 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _bcb9f562 = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _ccf7f7a8 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _1b5e2cc0 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _207c0fa6 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _12782589 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _663f8244 = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _3bd47ab1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _239ff43d = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _37da7ae0 = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _2f7a5c42 = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _1b29b4b6,
    name: "check-email"
  }, {
    path: "/login",
    component: _057c1dc8,
    name: "login"
  }, {
    path: "/logout",
    component: _11ceb88a,
    name: "logout"
  }, {
    path: "/medications",
    component: _6a07f134,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _6400fefa,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _25569585,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _f14ca90a,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _04dec614,
    name: "register"
  }, {
    path: "/registered",
    component: _b5fb079a,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _becdf8b4,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _931fe1ec,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _eafad8d8,
    name: "settings",
    children: [{
      path: "close-account",
      component: _6a63159d,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _baf82d82,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _11689c7e,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _19233ba3,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _ef17bbc8,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _993593ea,
      name: "settings-security"
    }, {
      path: "user",
      component: _54a27e96,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _bcb9f562,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _ccf7f7a8,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _1b5e2cc0,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _207c0fa6,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _12782589,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _663f8244,
    name: "redirect-new"
  }, {
    path: "/",
    component: _3bd47ab1,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _239ff43d,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _37da7ae0,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _2f7a5c42,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
